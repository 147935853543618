<template>
  <div contenteditable clipboard-file-uploader></div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    files: [],
    uploadSuccessFiles: [],
    loading: false,
  }),
  beforeDestroy() {
    this.removePasteListener()
  },
  mounted() {
    this.setupPasteListener()
  },
  methods: {
    active() {
      this.$snackbar.active({
        text: this.$t('upload_clipboard.help'),
      })
    },
    getDom() {
      const $dom = $(this.$el)
      return $dom
    },
    removePasteListener() {
      this.getDom()[0].removeEventListener('paste', () => {})
    },
    setupPasteListener() {
      this.getDom()[0].addEventListener('paste', (event) => {
        const items = event.clipboardData.items
        const imageFiles = []
        for(const item of items) {
          const file = item.getAsFile()
          if((file instanceof File) === false) continue
          if(new RegExp(/image/g).test(file.type) === false) continue
          imageFiles.push(file)
        }
        this.triggerUploadClipboardImages(imageFiles)
      }, false);
    },
    triggerUploadClipboardImages(imageFiles) {
      if(imageFiles.length == 0) return
      if(!this.multiple) imageFiles = [imageFiles[0]]
      this.$apopup.base({
        title: this.$t('upload_clipboard.confirm'),
        files: imageFiles,
        bodySlot: () => import('./clipboardPhotoUploaderConfirm.vue'),
        applyCallback: () => this.handleFileChange(imageFiles),
      })
    },
    async handleFileChange(files) {
      this.files = []
      if(files.length == 0) {
        return
      }

      if(this.loading) return
      this.loading = true
      this.uploadSuccessFiles = []
      for(let index=0; index<=files.length-1; index++) {
        const file = files[index]
        await this.uploadFile(file)
      }

      await this.$helper.delay(0.5)
      this.$emit('uploadAllCompleted', window.eagleLodash.cloneDeep(this.uploadSuccessFiles))
      this.loading = false
      this.init()
    },
    async uploadFile(file) {
      if(this.$helper.isImage(file) === false) {
        this.$snotify.error(
          this.$t('photo.validation.image.incorrect'),
          this.$t('photo.upload.fail')+`( ${file.name} )`
        )
        return
      }

      const formData = new FormData()
      formData.append('file', file)
      const params = {
        type: this.type,
        config: this.photoUploadConfig[this.type],
      }
      try {
        const result = await this.uploadApi(formData, this.onProgress, params)
        this.uploadSuccessFiles.push(result)
        this.$emit('appendPhoto', result)
        this.$snotify.success(
          file.name,
          this.$t('photo.upload.success')
        )
      } catch (error) {
        this.$emit('error')
        console.error(error)
        this.$snotify.error(
          null,
          this.$t('photo.upload.fail')
        )
      }
    },
    init() {
      this.files = []
      this.uploadSuccessFiles = []
    },
  },
  computed: {
    photoUploadConfig() {
      return this.$store.getters['photo/photoUploadConfig']
    },
    uploadApi() {
      return this.$api.collection.photoApi.upload
    },
  },
  watch: {
    loading() {
      this.$emit('onLoadingChange', this.loading)
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped>
*[clipboard-file-uploader]
  // 尺寸必須約等同父元素
  width: 30px
  height: 30px
  opacity: 0
  position: absolute
</style>
